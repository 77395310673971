import { render, staticRenderFns } from "./HonorIntroDia.vue?vue&type=template&id=44b06e1f&scoped=true&"
import script from "./HonorIntroDia.vue?vue&type=script&lang=js&"
export * from "./HonorIntroDia.vue?vue&type=script&lang=js&"
import style0 from "./HonorIntroDia.vue?vue&type=style&index=0&id=44b06e1f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44b06e1f",
  null
  
)

export default component.exports