<template>
    <div class='no_data center'>
      <img :src="noImg" alt="" class="image-404">
      <div class="title-404">{{tipText}}</div>
      <div class="desc-404">{{subTitle}}</div>
    </div>
  </template>
  
  <script>
  export default {
      props:{
        tipText:{//标题
            type:String,
            default:'未查询到结果'
        },
        noImg:{
            type:String,
            default:require('@/assets/img/eliteStyle/icon_nodata.png')
        },
         subTitle:{//子标题
            type:String,
            default:''
        },
      },
    data() {
      return {};
    },
    created() {
     
    },
    methods: {},
  };
  </script>
  
  <style lang="scss" scoped>
    // margin-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    // margin-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .no_data {
    height: 100%;
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .image-404 {
      width: 1.4rem;
      height: 1.4rem;
    }
    .title-404 {
      text-align: center;
      font-size: 0.14rem;
    font-weight: 500;
    color: #333333;
    line-height: 0.2rem;
    margin-top: 0.14rem;
    }
  }
  </style>