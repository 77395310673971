<template>
     <el-dialog
      :visible.sync="ifHornorDialog"
      title="个人介绍"
      :lock-scroll="false"
      center @close="closeFn">
      <div class="honor_intro">
        <HeadshotCell :item="infoData"></HeadshotCell>
        <div class="honor_desc">
          <div  v-html="introduce" v-if="introduce"></div>
          <NoData v-else tipText="简介完善中，敬请期待…"></NoData>
        </div>
      </div>
    </el-dialog>
    
  </template>
  
  <script>
  import HeadshotCell from './HeadshotCell.vue'
  import NoData from './NoData.vue'
  export default {
    name:'',
    components:{
      HeadshotCell,
      NoData
    },
    props: {
      ifHornorDialog: {//是否显示弹窗
          type:Boolean,
          default: false
      },
      infoData: {//精英 信息
          type:Object,
          default: ()=>{

          }
      },
    },
    computed:{
      introduce(){
        const _intro = this.infoData.introduce
        if(!_intro) {
          return ''
        }
        return _intro.replace(/\n/g, '<br/>')
      }

    },
    methods:{
      closeFn(){
        this.$emit('close')
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .honor_intro {
     display: flex;
     width: 100%;
    .honor_desc{
      margin-left: 0.1rem;
      flex:1
    }
    .no_data{
      margin: 0;
      justify-content: center;
    }
  }
  // 遮罩层
  ::v-deep .el-dialog{
    border-radius: 0.18rem;
    width: 50%;
    max-height: 80%;
    overflow: auto;
  }
  ::v-deep .el-dialog__header{
    position: relative;
  }
  :v-deep .el-dialog__title{
    font-size: 0.16rem;
  }
  ::v-deep .el-dialog__headerbtn{
    top: 0;
    bottom: 0;
    margin: auto;
  }
  @media only screen and (max-width: $moble_width) {
    .honor_intro{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    ::v-deep .el-dialog{
      width: 90%;
    }
    ::v-deep .el-dialog__body,
    ::v-deep .el-dialog__header{
      padding: pxToRem(10);
    }
  }
  </style>