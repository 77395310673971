<template>
    <!-- <div class="headshot_cell content high_light">
        <div class="bg"></div>
        <img class="pic" :src="item.pic" alt="">
        <div class="name">{{item.name}}</div>
    </div> -->
		<div class="list-item high_light">
            <div class="bg"></div>
            <div class="content">
              <img class="pic" :src="item.pic" alt="">
              <div class="name single_name" v-if="showType == '01'">{{item.name}}</div>
              <div class="name_wrap flex" v-if="showType == '02'">
                <div class="name">{{item.name}}</div>
                <div class="name_desc">
                  <div class="flex name_desc_item">介绍 <div class="icon_arrow_right"></div> </div>
                  <div class="flex name_desc_item">视频 <div class="icon_arrow_right"></div></div>
                </div>
              </div>
            </div>
          </div>
</template>
<script>
export default {
  name:'HeadshotCell',
  props: {
		showType:{ // 名称展示形式
			type:String,
			default:'01' //01仅展示名称 02展示介绍和视频按钮
		},
    item:{
       type:Object,
       default:()=>{
        return {
        }
       } 
    }

  }
}
</script>

<style lang="scss" scoped>
  .list-item {
        width: 2.2rem;
        height: 2.98rem;
        margin-bottom: 0.4rem;
        position: relative;
        margin-right: 0.54rem;
        flex-shrink: 0;
        &:hover,
				&.high_light {
          .bg {
            background-color: #fdefd5;
            &::after {
              background-color: #f6a52f;
            }
          }
          .content {
            .name_wrap,
						.single_name {
              background-image: url(../../../assets/img/eliteStyle/member_bg_active.png);
              color: #ffffff;
            }
            .icon_arrow_right{
              background-image: url(../../../assets/img/eliteStyle/icon_arrow_rightWhite.png);
            }
          }
        }
        .content {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          .pic {
            width: 2rem;
            margin-bottom: 0.1rem;
            // height: 100%;
            // opacity: .3;
          }
          // .name_wrap{
          //   width: 2.42rem;
          // }
          .name_desc{
            display: flex;
            align-items: center;
            justify-content: center;
            padding:0 0.1rem;
            box-sizing: border-box;
            font-size: 0.14rem;
            width: 100%;
            margin-top: 0.027rem;
          }
          .name_wrap {
            // width: 1.6rem;
            // height: 0.46rem;
            // border-radius: 50%;
            // border-radius: 0.23rem;
            // background-image: url(../../../assets/img/eliteStyle/member_bg.png);
            // background-size: 100% 100%;
            background: linear-gradient(90deg, #EAEFF4 0%, #D3DCEF 100%);
            border-radius: 0.396rem;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            font-size: 0.19rem;
            width: 2rem;
            flex-direction: column;
            padding: 0.117rem 0;
          }
          .flex{
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .name{
						width: 1.6rem;
            height: 0.46rem;
            border-radius: 0.23rem;
            text-align: center;
						background-image: url(../../../assets/img/eliteStyle/member_bg.png);
            background-size: 100% 100%;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.18rem;
          }
          .name_desc_item{
            font-size: 0.16rem;
            &:first-child{
              margin-right: 0.40rem;
            }
            &:hover{
              text-decoration: underline;
            }
          }
          .icon_arrow_right{
            display: inline-block;
            width: 0.08rem;
            height: 0.14rem;
            background-image: url(../../../assets/img/eliteStyle/icon_arrow_right.png);
            background-size: 100%;
            margin-left: 0.06rem;
          }
        }
        .bg {
          width: 2.2rem;
          height: 2.2rem;
          border-radius: 50%;
          background-color: #fafbfd;
          position: absolute;
          left: 0;
          bottom: 0;
          &::after {
            display: block;
            content: "";
            width: 2rem;
            height: 2rem;
            background-color: #d4dcee;
            border-radius: 50%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
          }
        }
      }
@media only screen and (max-width: $moble_width) {
  .list-item{
    margin-right: 0;
  }
}
</style>