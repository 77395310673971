<template>
     <el-dialog
      :visible.sync="ifShow"
      title="视频播放"
      :lock-scroll="false"
      center @close="closeFn">
      <div class="honor_intro">
        <video class="video-player"
           playsinline="true"
           webkit-playsinline="true"
           x5-video-player-type="h5"
           x5-video-player-fullscreen="true"   
           x5-video-orientation="portraint"
            :autoplay="false" controls
           show-center-play-btn=false
           show-play-btn=false
           id="video"
           ref="videoPlayRef"
           :poster="infoData.video_pic_url"
           v-if="infoData.video_url"
          
        >
          <source :src="infoData.video_url" type="video/mp4" >
          您的浏览器不支持 video 标签。
        </video>
        <NoData v-else tipText="视频完善中，敬请期待…"></NoData>
      </div>
    </el-dialog>
    
  </template>
  
  <script>
  import NoData from './NoData.vue'
  export default {
    name:'HonorVideoDia',
    components:{
      NoData
    },
    props: {
      ifShow: {//是否显示弹窗
          type:Boolean,
          default: false
      },
      infoData:{
       type:Object,
       default:()=>{
        return {
					// "id": 21,
					// "pic": "https://mingyaweb.oss-cn-beijing.aliyuncs.com/9c80fbb552ed4769833596338b1e3e24.png",
					// "name": "陆露飞",
					// "number": "MBJ060600149",
					// "type": "S",
					// "company": "北京分公司",
					// "introduce": null,
					// "order_first": "终身会员",
					// "order_second": "名人堂终身会员",
					// "order_value": 1,
					// "video_pic_url": 'http://oss.mingya.mobi/test/video/douyin/88f3983e159b461c942b3169a0584bac.png',
					// "video_url": 'http://oss.mingya.mobi/test/video/202108/c5ffe4cfe9974e45948e03b335213bb4.mp4'
        }
       } 
      }
    },
    data(){
      return {
        // :style="{'height':boxSize.offsetHeight +'px','width':boxSize.offsetWidth +'px'}"
        // boxSize:{
        //   offsetHeight:100,
        //   offsetWidth:200
        // }
      }
    },
    created(){
      // this.$nextTick(()=>{
      //   setTimeout(()=>{
           // this.sizeChange()
      //   },200)
      // })
    },
    methods: {
      //如果是16:9的话 需要计算  横向不用
      sizeChange(){
        const videoPlayRef =  document.querySelector('.video-player')
        let posterH = 0,posterW = 0
        if(videoPlayRef.offsetHeight*9/16 > videoPlayRef.offsetWidth ){//高宽比大于16:9 ok
          posterH = videoPlayRef.offsetWidth * 16/9
          posterW = videoPlayRef.offsetWidth
        }else{
          posterH = videoPlayRef.offsetHeight
          posterW =videoPlayRef.offsetHeight * 9/16
        }
        this.boxSize = {
          offsetHeight:posterH,
          offsetWidth:posterW
        }
        console.info('容器--' ,this.boxSize)
      },
      closeFn(){
        this.$emit('close')
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  // 遮罩层
  ::v-deep .el-dialog{
    border-radius: 0.18rem;
    width: 50%;
  }
  ::v-deep .el-dialog__header{
    position: relative;
  }
  :v-deep .el-dialog__title{
    font-size: 0.16rem;
  }
  ::v-deep .el-dialog__headerbtn{
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .video-player{
    width: 100%;
    border-radius: 0.18rem;
  }
  @media only screen and (max-width: $moble_width) {
    ::v-deep .el-dialog{
      width: 90%;
    }
    ::v-deep .el-dialog__body,
    ::v-deep .el-dialog__header{
      padding: pxToRem(10);
    }
  }
  </style>