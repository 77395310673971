<template>
  <div class="hof-member">
    <item-title :title="{name: '名人堂会员', en_1: 'MEMBERS', en_2: 'OF', en_3: 'ELITE', en_4: 'GROUP'}" />
    <div class="member-content">
      <section class="top">
        <img :src="topData.content" alt="">
      </section>
      <section class="list-box">
        <div class="search">
          <div class="cat-box">
            <div @click="orderFirstHandle(index, item.name)" v-for="(item, index) in position" :key="index" :class="[positionIndex == index ? 'active' : '', 'cat-item']">
              <img v-if="index == 0 && positionIndex != 0" class="icon" src="../../../assets/img/eliteStyle/member_1.png" alt="">
              <img v-if="index == 0 && positionIndex == 0" class="icon" src="../../../assets/img/eliteStyle/member_1_active.png" alt="">
              <img v-else-if="index == 1 && positionIndex != 1" class="icon" src="../../../assets/img/eliteStyle/member_2.png" alt="">
              <img v-else-if="index == 1 && positionIndex == 1" class="icon" src="../../../assets/img/eliteStyle/member_2_active.png" alt="">
              <img v-else-if="index == 2 && positionIndex != 2" class="icon" src="../../../assets/img/eliteStyle/member_3.png" alt="">
              <img v-else-if="index == 2 && positionIndex == 2" class="icon" src="../../../assets/img/eliteStyle/member_4_active.png" alt="">
              <img v-else-if="index == 3 && positionIndex != 3" class="icon" src="../../../assets/img/eliteStyle/member_4.png" alt="">
              <img v-else-if="index == 3 && positionIndex == 3" class="icon" src="../../../assets/img/eliteStyle/member_4_active.png" alt="">
              <span>{{item.name}}</span>
            </div>
          </div>
          <div class="search-box">
            <input v-model="params.name" @input="input" v-on:keyup.enter="searchHandle" class="keyword" type="text" placeholder="请输入会员姓名">
            <div @click="searchHandle" class="search-btn"></div>
          </div>
        </div>
        <div v-if="position[positionIndex] && position[positionIndex].children.length > 0" class="order-second">
          <div @click="orderSecondHandle(index, item.name)" v-for="(item, index) in position[positionIndex].children" :key="index" :class="[params.order_second == item.name ? 'active' : '', 'second-item']">{{item.name}}</div>
        </div>
        <div v-else class="order-second" style="height: .35rem;"></div>
        <div v-if="positionIndex == 0" class="tips">按照业绩达成时间排名</div>
        <div v-else class="tips">排名不分先后</div>
        <div v-if="listData.length > 0 && positionIndex == 0" class="list type-1">
          <div v-for="(item, index) in listData" :key="index" class="list-item" >
            <div class="bg"></div>
            <div class="content">
              <img class="pic" :src="item.pic" alt="">
              <!-- <div class="name">{{item.name}}</div> -->
              <div class="name_wrap flex">
                <div class="name" :class="{'text_align': exchangeName(item.name)}">{{item.name}}</div>
                <div class="name_desc">
                  <div class="flex name_desc_item" @click="selectItem(item,'desc')">介绍 <div class="icon_arrow_right"></div> </div>
                  <div class="flex name_desc_item" @click="selectItem(item,'video')">视频 <div class="icon_arrow_right"></div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="listData.length > 0 && positionIndex != 0" class="member-list">
          <div v-for="(item, index) in Math.ceil(listData.length / cols)" :key="index" class="row">
            <template v-for="(val, key) in cols">
              <div :key="key" class="item">
                <div v-if="listData[cols * index + key]" class="name">{{listData[cols * index + key].name}}</div>
                <div v-if="listData[cols * index + key]" class="address">{{listData[cols * index + key].company}}</div>
                <!-- <div v-if="listData[cols * index + key] && isSearch" class="address">{{listData[cols * index + key].order_first}} {{listData[cols * index + key].order_second}}</div> -->
                <div v-if="listData[cols * index + key] && isSearch" class="address">{{listData[cols * index + key].order_second}}</div>
              </div>
            </template>
          </div>
        </div>
        <div v-else-if="listData.length == 0" class="nodata">
          <div class="title">未找到相关结果</div>
        </div>
        <pager :total="total" :page="params.page" :limit="params.limit" @current-change="handleCurrentChange"></pager>
      </section>
    </div>
    <!-- 荣誉弹窗组件 -->
   <HonorIntroDia :ifHornorDialog.sync="ifHornorDialog" :infoData="currentPersion" @close="onClose"></HonorIntroDia>
   <!-- 视频弹窗组件 -->
   <HonorVideoDia v-if="ifHornorVideoDialog" :ifShow.sync="ifHornorVideoDialog" :infoData="currentPersion"  @close="onClose"></HonorVideoDia>
  </div>
</template>

<script>
import ItemTitle from '@/components/common/itemTitle'
import { getCommonPageData, getMemberListData } from '@/api/eliteStyle'
import Pager from '@/components/common/Pager.vue'
import { isMobile } from '@/helper/utils'
import HonorIntroDia from '../components/HonorIntroDia.vue'
import HonorVideoDia from '../components/HonorVideoDia.vue'
export default {
  components: {
    ItemTitle,
    Pager,
    HonorIntroDia,
    HonorVideoDia
  },
  data() {
    return {
      cols: isMobile() ? 2 : 4,
      topData: {},
      params: {
        order_first: '终身会员',
        order_second: '',
        name: '',
        company: '',
        page: 1,
        limit: 36
      },
      total: 0,
      positionIndex: 0,
      childIndex: 0,
      position: [
        { name: '终身会员', children: [] },
        {
          name: '钻石会员',
          children: [
            { name: '三星钻石' },
            { name: '二星钻石' },
            { name: '一星钻石' },
          ]
        },
        {
          name: '金牌会员',
          children: [
            { name: '三星金牌' },
            { name: '二星金牌' },
            { name: '一星金牌' }, 
          ]
        },
        {
          name: '银牌会员',
          children: [
            { name: '三星银牌' },
            { name: '二星银牌' },
            { name: '一星银牌' }, 
          ]
        }
      ],
      listData: [],
      isSearch: false,
      isShowPosition: false,
      ifHornorDialog:false,//自我介绍弹窗 true展示
      ifHornorVideoDialog:false,//自我介绍弹窗 true展示
      currentPersion:{} //当前查看经纪人信息
    }
  },
  created() {
    this.getPageData()
    this.getMemberList()
  },
   watch:{
    $route(to, from){
      if (from.name != 'eliteDetail' && to.name == 'member') {
        this.params = {
          order_first: '终身会员',
          order_second: '',
          name: '',
          company: '',
          page: 1,
          limit: 36
        }
        this.positionIndex = 0
        this.childIndex = 0
        this.getPageData()
        this.getMemberList()
        // console.log(this.getNewsData)
      }      
    }
  },
  methods: {
    input() {
      // if (!this.params.name) {
      //   this.isSearch = false
      // }
    },
    searchHandle() {
      this.params.page = 1
      this.positionIndex = -1
      this.params.order_first = '会员'

      this.isSearch = true
      this.getMemberList()
    },
    orderSecondHandle(index, name) {
      this.params.page = 1
      this.params.name = ''
      this.childIndex = index
      this.params.order_second = name
      this.getMemberList()
    },
    orderFirstHandle(index, name) {
      this.params.page = 1
      this.params.name = ''
      this.positionIndex = index
      this.params.order_first = name
      this.childIndex = 0
      this.params.order_second = this.position[index].children[0] ? this.position[index].children[0].name : ''
      this.getMemberList()
    },
    async getPageData() {
      const res = await getCommonPageData({ id: 17 })
      this.topData = res.list[0] || {}
      // console.log(res)
    },
    handleCurrentChange(val) {
      this.params.page = val
      this.$emit('loading', false)
      this.getMemberList()
    },
    // 获取会员列表
    async getMemberList() {
      let params = JSON.parse(JSON.stringify(this.params))
      if (this.positionIndex == 0) {
        // params.limit = 10000
      }
      if(params.name) {
        params.order_second = ''
      }
      const res = await getMemberListData(params)
      this.listData = []
      this.listData = res.records
      this.total = res.total
      this.$emit('loading', false)
      if (params.name) {
        this.isSearch = true
      } else {
        this.isSearch = false
      }
    },
    // 点击事件
    /**
     * 
     * @param {*} item 基本信息
     * @param {*} type 按钮类型 desc简介 video视频
     */
    selectItem(item,type){
      // this.currentPersion = {...item,
      //   "video_pic_url": 'https://oss.mingya.mobi/test/video/douyin/88f3983e159b461c942b3169a0584bac.png',
			// 		// "video_url": 'https://oss.mingya.mobi/test/video/202108/c5ffe4cfe9974e45948e03b335213bb4.mp4'
			// 		"video_url": 'https://mingya-web.oss-cn-beijing.aliyuncs.com/82fcc95246a84411ae2ead240c3a608c.mp4'
      //   }
      this.currentPersion = item
      this.ifHornorDialog = type == 'desc'? true : false
      this.ifHornorVideoDialog = type == 'video'? true : false
    },
    onClose(){
      this.currentPersion = {}
      this.ifHornorDialog = false
      this.ifHornorVideoDialog = false
    },
    exchangeName(name){
      return name.length < 3 ? true: false
    }
  }
}
</script>

<style lang="scss" scoped>
.member-content {
  padding-top: 0.6rem;
  .top {
    img {
      display: flex;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
      // border-radius: .1rem 0 0 .1rem;
    }
  }
  .nodata {
    border-bottom: 1px solid #dcdcdc;
    padding-bottom: 0.3rem;
    margin-top: 0.5rem;
    position: relative;
    font-size: 0.18rem;
    &::after {
      content: "";
      display: block;
      width: 1.24rem;
      height: 0.04rem;
      border-radius: 0.02rem;
      background-color: #fe6917;
      position: absolute;
      left: 0;
      bottom: -0.01rem;
    }
  }
  .member-list {
    margin-top: 0.3rem;
    border: 1px solid #eeeeee;
    width: 100%;
    .row {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      margin-left: 0;
      &:nth-child(odd) {
        background-color: #f7f7f7;
      }
      .item {
        padding: 0.2rem 0.3rem;
        position: relative;
        box-sizing: border-box;
        flex: 1;
        display: inline-block;
        // height: 0.9rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // padding: 0 .35rem;
        // border-right: 1px solid #EEEEEE;
        .name {
          font-size: 0.2rem;
        }
        .address {
          color: #999999;
          font-size: 0.14rem;
        }
        &::after {
          display: block;
          content: "";
          width: 1px;
          height: 0.4rem;
          background-color: #eeeeee;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
        &:last-child::after {
          display: none;
        }
      }
    }
  }
  .list {
    margin-top: 0.05rem;
    &.type-1 {
      display: flex;
      flex-wrap: wrap;
      .list-item {
        width: 2.2rem;
        height: 2.98rem;
        margin-bottom: 0.4rem;
        position: relative;
        margin-right: 0.54rem;
        &:nth-child(4n) {
          margin-right: 0;
        }
        .content {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;
          .pic {
            width: 2rem;
            margin-bottom: 0.1rem;
            // height: 100%;
            // opacity: .3;
          }
          // .name_wrap{
          //   width: 2.42rem;
          // }
          .name_desc{
            display: flex;
            align-items: center;
            justify-content: center;
            padding:0 0.1rem;
            box-sizing: border-box;
            font-size: 0.14rem;
            width: 100%;
            margin-top: 0.027rem;
            color: #666;
          }
          .name_wrap {
            // width: 1.6rem;
            // height: 0.46rem;
            // border-radius: 50%;
            // border-radius: 0.23rem;
            // background-image: url(../../../assets/img/eliteStyle/member_bg.png);
            // background-size: 100% 100%;
            background: linear-gradient(90deg, #EAEFF4 0%, #D3DCEF 100%);
            border-radius: 0.396rem;
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            font-size: 0.19rem;
            width: 2rem;
            flex-direction: column;
            padding: 0.117rem 0;
          }
          .flex{
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .name{
            text-align: center;
            color: #333;
            font-size: 0.20rem;
            &.text_align{
              width: 0.6rem;
              text-align: justify;
              text-align-last: justify;
              text-justify: distribute-all-lines;
            }
          }
          .name_desc_item{
            font-size: 0.16rem;
            &:first-child{
              margin-right: 0.40rem;
            }
            &:hover{
              text-decoration: underline;
            }
          }
          .icon_arrow_right{
            display: inline-block;
            width: 0.08rem;
            height: 0.14rem;
            background-image: url(../../../assets/img/eliteStyle/icon_arrow_right.png);
            background-size: 100%;
            margin-left: 0.06rem;
          }
        }
        .bg {
          width: 2.2rem;
          height: 2.2rem;
          border-radius: 50%;
          background-color: #fafbfd;
          position: absolute;
          left: 0;
          bottom: 0;
          &::after {
            display: block;
            content: "";
            width: 2rem;
            height: 2rem;
            background-color: #d4dcee;
            border-radius: 50%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
          }
        }
        &:hover {
          .bg {
            background-color: #fdefd5;
            &::after {
              background-color: #f6a52f;
            }
          }
          .content {
            .name_wrap {
              // background-image: url(../../../assets/img/eliteStyle/member_bg_active.png);
              background: linear-gradient(90deg, #FFB62C 0%, #FE7018 100%);
              color: #ffffff;
            }
            .name_desc_item,.name{
              color: #fff;
            }
            .icon_arrow_right{
              background-image: url(../../../assets/img/eliteStyle/icon_arrow_rightWhite.png);
            }
          }
        }
      }
    }
  }
  .order-second {
    display: flex;
    align-items: center;
    margin-top: 0.4rem;
    color: #666666;
    font-size: 0.16rem;
    .second-item {
      margin-right: 0.3rem;
      cursor: pointer;
      &.active {
        border-bottom: 1px solid #fe6917;
        color: #fe6917;
      }
    }
  }
  .list-box {
    margin-top: 0.6rem;
    position: relative;
    .tips {
      color: #cccccc;
      font-size: .16rem;
      text-align: right;
      position: absolute;
      right: 0;
      top: .9rem;
    }
    .search {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .search-box {
        width: 3.6rem;
        height: 0.48rem;
        display: flex;
        align-items: center;
        // box-shadow: 0px 0px .3rem 0px rgba(0, 0, 0, 0.1);
        .keyword {
          height: 100%;
          flex: 1;
          border: 0 none;
          box-sizing: border-box;
          padding: 0 0.3rem;
          font-size: 0.16rem;
          border: 1px solid #ffffff;
          transition: all 0.3s;
          box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
          appearance: none;
          -webkit-appearance: none;
          &:focus {
            border: 1px solid #fe6917;
            background: #ffffff;
            /* 解决input按钮自动填充后 浏览器渲染的默认背景问题 */
            box-shadow: inset 0 0 0 1000px #ffffff !important;
          }
          border-radius: 0.24rem 0 0 0.24rem;
          outline: none;
        }
        .search-btn {
          height: 100%;
          width: 0.8rem;
          border-radius: 0 0.4rem 0.4rem 0;
          background-image: url(../../../assets/img/eliteStyle/search_btn_bg.png);
          background-size: 100% 100%;
          cursor: pointer;
        }
      }
      .cat-box {
        display: flex;
        align-items: flex-start;
        .cat-item {
          width: 1.5rem;
          height: 0.48rem;
          font-size: 0.16rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f7f7f7;
          &.active {
            background-color: none;
            background-image: url(../../../assets/img/eliteStyle/cat_small_active.png);
            background-size: 100% 100%;
            color: #ffffff;
            height: 0.53rem;
            padding-bottom: 0.05rem;
          }
          border-radius: 0.24rem;
          margin-right: 0.2rem;
          cursor: pointer;
          img {
            width: auto;
            height: 0.15rem;
            margin-right: 0.07rem;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: $moble_width) {
  .member-content {
    .list-box {
      .tips {
        top: 2.3rem;
      }
      .search {
        flex-wrap: wrap;
        .search-box {
          width: 100%;
        }
        .cat-box {
          flex-wrap: wrap;
          flex-shrink: 0;
          width: 100%;
          .cat-item {
            margin-bottom: 0.2rem;
          }
        }
      }
    }

    .list.type-1 {
      .list-item {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
</style>